<template>
    <div id="preview-section" class="mt-sm-5 mt-3">
        <div class="container">
            <div class="row g-1 align-items-center" v-if="prescription.patient">
                <div class="col-md-2">
                    <p class="fw-bold fs-14">Date : </p>
                    <p class="fs-14">{{ prescription.date | dateFormat }}</p>
                </div>
                <div class="col-md-3">
                    <p class="fw-bold fs-14">Doctor Name : </p>
                    <p class="fs-14">{{ prescription.doctor ? prescription.doctor.name : '' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="fw-bold fs-14">Patient : </p>
                    <p class="fs-14"> {{ prescription.patient.full_name }} | {{ prescription.patient.id_number }}</p>
                </div>
                <div class="col-md-2">
                    <p class="fw-bold fs-14">Age :</p>
                    <p class="fs-14">
                        {{ prescription.patient.date_of_year }} Year
                        {{ prescription.patient.date_of_month }} Month
                    </p>
                </div>
                <div class="col-md-1">
                    <p class="fw-bold fs-14">Sex :</p>
                    <p class="fs-14">
                        {{ prescription.patient.gender }}
                    </p>
                </div>
            </div>
            <div class="row border-top my-4">
                <div class="col-sm-3 pe-4 py-3 border-end">
                    <PreviewLeftItem title="C/C" :item="getItemValue('cc')"/>
                    <PreviewLeftItem title="O/E" :item="getItemValue('oe')"/>
                    <PreviewLeftItem title="X-Ray" :item="getItemValue('x-ray')"/>
                    <!--                    <PreviewLeftItem title="OPG" :item="getItemValue('opg')"/>-->
                    <div class="d-flex justify-content-between align-items-center">
                        <label>
                            OPG:
                        </label>
                        <div class="custom-checkbox pointer-event position-relative ">
                            <input id="opg" type="checkbox" :checked="prescription.opg_checked">
                            <label for="opg"></label>
                        </div>
                    </div>
                    <PreviewLeftItem title="Adv" :item="getItemValue('adv')"/>
                    <PreviewLeftItem title="Treatment Plan" :item="getItemValue('treatment_plan')"/>
                    <div class="item mb-2 d-flex align-items-center">
                        <div>Inv:</div>
                        <div class="fs-12 border p-2 ms-2 w-100 min-h-33 ">{{ prescription.inv }}</div>
                    </div>
                    <div class="item mb-2 d-flex align-items-center">
                        <div>BP:</div>
                        <div class="fs-12 border p-2 ms-2 w-100 min-h-33 ">{{ prescription.bp }}</div>
                    </div>
                    <div class="item mb-2 d-flex align-items-center">
                        <div>Diabetes:</div>
                        <div class="fs-12 border p-2 ms-2 w-100 min-h-33 ">{{ prescription.diabetes }}</div>
                    </div>
                    <div class="item mb-2 d-flex align-items-center">
                        <div>Temp:</div>
                        <div class="fs-12 border p-2 ms-2 w-100 min-h-33 ">{{ prescription.temp }}</div>
                    </div>
                    <div class="item mb-2 d-flex align-items-center">
                        <div>Next Visit:</div>
                        <div class="fs-12 border p-2 ms-2 w-100 min-h-33 ">{{ prescription.next_visit | dateTimeFormat }}</div>
                    </div>
                </div>
                <div class="col-sm-9 ps-4">
                    <h3 class="my-3">Rx:</h3>
                    <div class="border p-4 bg-light min-vh-50">
                        <div class="medicine-item">
                           <div class="table-responsive prescription-table">
                               <table class="table table-striped table-hover">
                                   <thead>
                                   <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Medicine Name</th>
                                       <th scope="col">Dose</th>
                                       <th scope="col" style="width: 15px" class="text-center">Days</th>
                                   </tr>
                                   </thead>
                                   <tbody>
                                   <tr v-for="(medicine, index) in prescription.medicines">
                                       <th scope="row"> {{ index + 1 }}</th>
                                       <td>{{ medicine.medicine? medicine.medicine.name_with_category : '' }}</td>
                                       <td class="ms-auto">{{ medicine.dose }}</td>
                                       <td class="mx-5 px-5 text-center">{{ medicine.duration }}</td>
                                   </tr>
                                   </tbody>
                               </table>
                           </div>
                        </div>
                    </div>
                    <div class="mt-3 text-center d-flex gap-2 justify-content-center prescription-btn">
                        <router-link :to="{name: 'prescriptionEdit', params: {id: prescription.id}}"
                                     class="bg-primary btn mt-3 px-5 py-3 text-light">
                            Edit Visit
                        </router-link>
                        <router-link v-if="$root.$can('bill', 'bill-create')"
                                     :to="{name: 'invoiceCreate', query: {patient_id: prescription.patient.id}}"
                                     class="bg-primary btn mt-3 px-5 py-3 text-light">
                            Bill
                        </router-link>
                        <button type="button" @click="print(prescription.id)"
                                class="bg-warning btn mt-3 px-5 py-3 text-light">
                            Print
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <PrescriptionPrint ref="print_view" v-if="print_view"/>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import PreviewLeftItem     from "./includes/PreviewLeftItem";
import PrescriptionPrint   from "./print/Index";

export default {
    name      : "Preview",
    components: {PrescriptionPrint, PreviewLeftItem},
    data      : () => ({
        print_view  : false,
        prescription: {
            date       : '',
            patient_id : '',
            inv        : '',
            bp         : '',
            diabetes   : '',
            opg_checked: '',
            patient    : {
                id           : '',
                name         : '',
                last_name    : '',
                date_of_year : '',
                date_of_month: '',
                gender       : '',
            },
            items      : [],
            medicines  : [],
        },
    }),
    mounted() {
        this.getPrescription(this.$route.params.id)
    },
    methods: {
        print(prescription_id) {
            this.print_view         = true;
            const component_created = setInterval(() => {
                if (this.$refs['print_view']) {
                    this.$refs['print_view'].getPrescription(prescription_id);
                    clearInterval(component_created);
                }
            }, 100);
        },
        getItemValue(type) {
            let found = this.prescription.items.find((o) => o.type === type);
            if (found) {
                return found
            } else {
                return {
                    top_left    : '',
                    top_right   : '',
                    bottom_left : '',
                    bottom_right: '',
                    comment     : ''
                }
            }
        },
        getPrescription(id) {
            ApiService.get(`/prescriptions/${id}`).then((res) => {
                this.prescription = res.data.data;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
    }
}
</script>

<style scoped>

.medicine-item {
    height: 500px;
    overflow: auto;
}

.border-end {
    height: 615px;
    overflow: auto;

}
</style>
